import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

// 导入 router
import router from './router'

// 导入bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// 导入Element Plus
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

// 使用 store 和 router，并挂载应用
createApp(App)
    .use(store)   // 使用 Vuex store
    .use(router)  // 使用 Vue Router
    .use(ElementPlus) // 使用 Element Plus
    .mount('#app')
