<template>
  <!-- <p>Tang</p> -->
  <!-- <CodeEditor></CodeEditor> -->
  <NarBar></NarBar>
</template>

<script>
import NarBar from "@/components/NarBar.vue";
// import CodeEditor from "@/components/CodeEditor.vue";

export default {
  components: { NarBar },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 0%; */
}
</style>
