import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/home/HomeView.vue"; // 替换为你自己的组件
import About from "../views/AboutVue.vue";
import UploadImageVue from "@/views/users/UploadImageVue.vue";
import ImVue from "@/views/chat/ImVue.vue";
import UploadVedioVue from "@/views/users/UploadVedioVue.vue";
import SpiderView from "@/views/SpiderView.vue";
import MarkdownEditor from "@/views/users/MarkdownEditor.vue";
import ProblemsIndex from "@/views/problems/IndexVue.vue";
import ProblemContent from "@/views/problems/ContentVue.vue";

// 导入 store
// import store from "@/store";

const routes = [
  {
    path: "/problems/content",
    name: "problemsContent",
    component: ProblemContent,
  },
  {
    path: "/problems/index",
    name: "problemsIndex",
    component: ProblemsIndex,
  },
  {
    path: "/markdown",
    name: "markdown",
    component: MarkdownEditor,
  },
  {
    path: "/spider",
    name: "spider",
    component: SpiderView,
  },
  {
    path: "/chat",
    name: "chat",
    component: ImVue,
  },
  {
    path: "/uploadVideo",
    name: "uploadVideo",
    component: UploadVedioVue,
  },
  {
    path: "/uploadImage",
    name: "uploadImage",
    component: UploadImageVue,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  // 在这里添加更多的路由
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// 添加全局路由守卫
// router.beforeEach((to, from, next) => {
//     // 检查用户是否登录
//     const isLogin = store.state.user.is_login;

//     // 如果没有登录且试图访问非首页（`/`）的路由
//     if (!isLogin && to.path !== '/') {

//         // 弹出提示框
//         alert('请先登录');
//         // 重定向到首页或登录页面
//         next('/');
//     } else {
//         next(); // 继续导航
//     }
// });

export default router;
