import $ from 'jquery';

export default {
    state: {
        uid: "",
        username: "",
        avatar: "",
        access_token: "",
        refresh_token: "",
        is_login: false
    },
    getters: {
    },
    mutations: {  // 修改值
        updateLoginUser(state, user) {
            state.uid = user.uid,
            state.username = user.username,
            state.avatar = user.avatar,
            state.is_login = true
            
        },
        updateToken(state, tokenList) {
            state.access_token = tokenList.access_token,
            state.refresh_token = tokenList.refresh_token
        },
        logout(state) {
            state.uid = "",
            state.username = "",
            state.avatar = "",
            state.access_token = "",
            state.refresh_token = "",
            localStorage.removeItem("access_token"),
            localStorage.removeItem("refresh_token"),
            state.is_login = false
        }

    },
    actions: {  // get, post

        login(context, data) {
            $.ajax({
                url: "http://127.0.0.1:8080/user/login",
                type: "post",
                data: {
                    username: data.username,
                    password: data.password,
                },
                success(resp) {
                    if (resp.err === "success") {
                        localStorage.setItem("jwt_token", resp.token);
                        context.commit("updateLoginToken", resp.token);
                    }
                },
                error() {
                    // console.log(resp)
                }
            })
        },

        logout(context) {
            localStorage.removeItem("jwt_token");
            context.commit("logout");
        }
    },
    modules: {
    }
}
