<template>
    <div id="app">
      <h1>Markdown 编辑器</h1>
      <MarkdownEditor />
    </div>
  </template>
  
  <script>
  import MarkdownEditor from "../../components/MarkdownEditor.vue"
  
  export default {
    name: "App",
    components: { MarkdownEditor },
  };
  </script>
  
  <style>
  #app {
    text-align: center;
    margin-top: 50px;
  }
  </style>
  