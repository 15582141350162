<template>
  <div class="container">
    <!-- 上部内容 -->
    <div class="top">
      <!-- 左侧内容 -->
      <div class="left">
        <div v-html="renderedContent"></div>
      </div>

      <!-- 右侧内容 -->
      <div class="right">
        <!-- 这里是右侧内容，可以添加其他内容 -->
        <button @click="clibtn">点击</button>
        <div class="markdown-editor">
          <!-- 用户输入的 Markdown 内容 -->
          <textarea
            v-model="markdownContent"
            placeholder="输入 Markdown 内容"
          ></textarea>

          <!-- 渲染实时效果 -->
          <div
            class="markdown-preview"
            v-html="renderedmarkdownEditorContent"
          ></div>
        </div>
      </div>
    </div>

    <!-- <br /> -->
    <!-- Monaco Editor -->
    <div id="monaco-container" style="height: 400px; text-align: left"></div>
  </div>
</template>

<script>
import { ref, computed, onMounted, nextTick } from "vue"; // 导入 nextTick
import MarkdownIt from "markdown-it";
import katex from "markdown-it-katex";
import "katex/dist/katex.min.css"; // 引入 KaTeX 样式
import markdownit from "markdown-it";
import { useRoute } from "vue-router";
// 导入 Monaco Editor
import * as monaco from "monaco-editor";

export default {
  setup() {
    const markdownContent = ref(
      `### 欢迎使用 Markdown 编辑器\n\n您可以在这里输入 Markdown 格式的文本，看看实时效果！\n\n例如：数学公式 $E = mc^2$`
    );

    const route = useRoute();
    const content1 = ref(
      "数组操作\n\n### 题目描述\n\n给出一个长度为 $n$ 的数组 $\\{A_i\\}$，由 1 到 $n$ 标号 ， 你需要维护 $m$ 个操作。\n\n操作分为三种，输入格式为：\n\n1. $l\\ r\\  d$，将数组中下标 $l$ 到 $r$ 的位置都加上 d，即对于$l  \\leq  i  \\leq  r$，执行 $A_i=A_i+d$。\n\n2. $l_1\\ r_1\\ l_2\\ r_2$，将数组中下标为 $l_1$ 到 $r_1$ 的位置，赋值成 $l_2$ 到 $r_2$的值，保证 $r_1-l_1=r_2-l_2$。换句话说先对 $0  \\leq  i  \\leq  r_2-l_2$ 执行 $B_i=A_(l_2+i)$，再对 $0  \\leq  i  \\leq  r_1-l_1$执行 $A_(l_1+i)=B_i$，其中 $\\{B_i\\}$ 为一个临时数组。\n\n3. $l\\ r$，求数组中下标 $l$ 到 $r$ 的位置的和，即求出$\\sum_{i=1}^{r}A_i$ 。\n\n### 输入描述\n\n第一行一个整数 Case，表示测试点编号，其中 Case=0 表示该点为样例。\n\n第二行包含两个整数 $n,m$。保证 $1  \\leq  n,m  \\leq  10^5$。\n\n第三行包含 $n$ 个整数 $A_i$，表示这个数组的初值。保证 $0  \\leq  A_i  \\leq  10^5$。\n\n接下来 m 每行描述一个操作，格式如问题描述所示。\n\n其中，$0  \\leq  d  \\leq  10^5$，$1 \\leq  l \\leq  r  \\leq  n$，$1  \\leq  l_1  \\leq  r_1  \\leq  n1  \\leq  l_2  \\leq  r_2  \\leq  n$，$r_1-l_1=r_2-l_2$。\n\n### 输出描述:\n\n对于每次 3 操作输出一行一个数，表示求和的结果。\n\n### 输入输出样例\n\n#### 示例\n\n\u003E 输入\n\n```txt\n0\n5 6\n1 2 3 4 5\n2 1 3 3 5\n3 3 5\n1 2 4 2\n3 3 5\n2 1 3 3 5\n3 1 5\n```\n\n\u003E 输出\n\n```txt\n14\n18\n29\n```"
    );
    console.log("content.value---right: ", content1.value);
    const content = ref(route.query.content || "默认内容");
    // const id = ref(route.query.id);
    console.log(" content.value--worng: ", content.value);

    // 计算属性：渲染 Markdown 内容
    const renderedContent = computed(() => {
      const md = new MarkdownIt({ html: true, breaks: true }).use(katex); // 启用 KaTeX 支持

      const formattedContent = ref("");
      // 在计算属性外部进行换行符替换
      formattedContent.value = content.value
        .replace(/\\n/g, "\n") // 处理换行符
        .replace(/\\u003E/g, ">") // 转义 \u003E 为 ">"
        .replace(/\\\\/g, "\\") // 如果有 \\转为 \;
        .replace(/\\u002F/g, "/") // 图片替换
        .replace(
          /https:\/\/doc\.shiyanlou\.com\/courses\/([^)\s]+)/g, // 匹配完整的路径
          "/image/lq/$1.png" // 替换为目标路径并添加 .png 后缀
        );
      // .replace(/\\leq/g, "\\\\leq");
      // .replace(
      //   /\(https?:\/\/[^\/]+\/courses\/([^\/\)]+)\)/g,
      //   "(/image/lq/$1)"
      // ); // 图片替换

      console.log("Rendered Content: ", formattedContent.value); // 调试输出渲染后的 HTML
      const true_val = ref(
        "倍数问题\n\n### 题目描述\n\n众所周知，小葱同学擅长计算，尤其擅长计算一个数是否是另外一个数的倍数。但小葱只擅长两个数的情况，当有很多个数之后就会比较苦恼。现在小葱给了你\n$n$ 个数，希望你从这 $n$ 个数中找到三个数，使得这三个数的和是 $K$ 的倍数，且这个和最大。数据保证一定有解。\n\n### 输入描述\n\n第一行包括 2 个正整数 $n, K$。\n\n第二行 $n$ 个正整数，代表给定的 $n$ 个数。\n\n其中，$1   \\leq   n   \\leq   10^5, 1   \\leq   K   \\leq   10^3$，给定的 $n$ 个数均不超过 $10^8$。\n\n### 输出描述\n\n输出一行一个整数代表所求的和。\n\n### 输入输出样例\n\n#### 示例\n\n\u003E 输入\n\n```txt\n4 3\n1 2 3 4\n```\n\n\u003E 输出\n\n```txt\n9\n```"
      );
      const rendered = md.render(formattedContent.value);

      console.log("true_val Rendered Content: ", true_val.value);
      return rendered;
    });

    const renderedmarkdownEditorContent = computed(() => {
      const md = new MarkdownIt({ html: true }).use(katex); // 启用 KaTeX 支持
      return md.render(markdownContent.value); // 渲染并返回 HTML
    });

    // 处理点击按钮的事件
    const clibtn = () => {
      const md = markdownit();
      const result = md.render(content.value); // 渲染 Markdown 内容
      console.log("result: ", result);
    };

    // 用于双向绑定 Monaco Editor 的内容
    const monacoContent = ref();

    // 生命周期钩子：组件挂载完成后处理样式
    onMounted(() => {
      // 等待 DOM 加载完成后修改样式
      nextTick(() => {
        const targetElements = document.querySelectorAll(".vlist span[style]");
        targetElements.forEach((span) => {
          // 修改字体和样式
          span.style.fontSize = "0.7em";
          span.style.top = "-1.363em"; // 修改 top 样式
          span.style.marginRight = "0.05em"; // 修改 margin-right 样式
        });

        const editor = monaco.editor.create(
          document.getElementById("monaco-container"),
          {
            value:
              "// First line\nfunction hello() {\n\talert('Hello world!');\n}\n// Last line",
            language: "javascript",

            lineNumbers: "off",
            roundedSelection: false,
            scrollBeyondLastLine: false,
            readOnly: false,
            theme: "vs-dark",
          }
        );
        setTimeout(function () {
          editor.updateOptions({
            lineNumbers: "on",
          });
        }, 2000);
      });
    });

    // 返回所有需要在模板中访问的变量和方法
    return {
      content,
      markdownContent,
      renderedContent,
      renderedmarkdownEditorContent,
      clibtn,
      monacoContent,
    };
  },
};
</script>

<style scoped>
/* 使用 Flexbox 创建左右布局 */
.container {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
}

/* 左侧内容占 80% 宽度 */
.left {
  width: 80%;
  padding-right: 20px; /* 控制与右边内容的间距 */
  text-align: left; /* 右对齐 */
  background-color: white;
}

/* 右侧内容占 20% 宽度 */
.right {
  width: 20%;
  padding-left: 20px; /* 控制与左边内容的间距 */
  background-color: white;
}

/* 可选的样式调整 */
h3 {
  font-size: 1.5em;
  margin-top: 20px;
}

p {
  line-height: 1.6;
  font-size: 1em;
}

/* 样式设置 */
.markdown-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

textarea {
  width: 100%;
  height: 200px;
  font-size: 16px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.markdown-preview {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  white-space: pre-wrap;
}
.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
