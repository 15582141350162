<template>
  <div>
    <!-- 顶部导航栏 -->
    <nav class="navbar navbar-light bg-light">
      <button @click="toggleSidebar" class="btn btn-outline-secondary">
        <i class="bi bi-list"></i>
      </button>
      <router-link class="nav-link" to="/problems/index"> 题库</router-link>
      <div>456</div>
      <div>789</div>
      <a class="navbar-brand ms-2" href="#">My App</a>
      <!-- 登录按钮，点击后弹出登录框 -->
      <!-- 根据 is_login 动态显示 -->
      <div
        v-if="!isLogin"
        @click="showLoginModal"
        style="margin-right: 5%; cursor: pointer"
      >
        登入
      </div>
      <!-- 登录后显示头像和下拉框 -->
      <div v-if="isLogin" class="dropdown" style="margin-right: 5%">
        <img
          :src="avatar"
          alt="Avatar"
          style="width: 40px; height: 40px; border-radius: 50%; cursor: pointer"
          class="dropdown-toggle"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        />

        <!-- 箭头 -->
        <i
          class="bi bi-caret-down-fill"
          style="margin-left: 5px; cursor: pointer"
        ></i>
        <ul
          class="dropdown-menu dropdown-menu-end"
          aria-labelledby="dropdownMenuButton"
        >
          <li><a class="dropdown-item" href="#">我的空间</a></li>
          <li><a class="dropdown-item" href="#">我的订单</a></li>
          <li><hr class="dropdown-divider" /></li>
          <li>
            <a class="dropdown-item" href="#" @click="logout">退出登录</a>
          </li>
        </ul>
      </div>
    </nav>

    <!-- 登录模态框 -->
    <div
      class="modal fade"
      :class="{ show: showModal }"
      style="display: block"
      tabindex="-1"
      aria-labelledby="loginModalLabel"
      aria-hidden="true"
      v-if="showModal"
    >
      <div class="modal-dialog" style="width: 20%">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title qqlogin" id="loginModalLabel">登录</h5>
            <button
              type="button"
              class="btn-close"
              @click="closeLoginModal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <!-- 登录表单 -->
            <form @submit.prevent="login">
              <!-- 用户名输入框 -->
              <div class="mb-3">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1"
                    ><i class="bi bi-person-fill"></i
                  ></span>
                  <input
                    type="text"
                    class="form-control"
                    id="username"
                    style="cursor: pointer"
                    v-model="username"
                    placeholder="用户名"
                    required
                  />
                </div>
              </div>

              <!-- 密码输入框 -->
              <div class="mb-3">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon2"
                    ><i class="bi bi-lock-fill"></i
                  ></span>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    style="cursor: pointer"
                    v-model="password"
                    placeholder="密码"
                    required
                  />
                </div>
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                @click="login"
                style="cursor: pointer"
              >
                登录
              </button>
            </form>
          </div>

          <div class="modal-footer">
            <!-- 社交登录按钮 -->

            <div style="width: 100%">
              <div style="float: left; cursor: pointer">忘记密码</div>
              <div style="float: right; cursor: pointer">注册</div>
            </div>

            <div style="width: 100%">
              <a style="float: left">更多登录方式：</a>
              <a style="float: left" @click="qqLogin"
                ><img
                  src="../assets/image/qq.jpg"
                  style="height: 24px; cursor: pointer"
              /></a>
              <a></a>
              <a></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 主体内容 -->
    <div class="d-flex">
      <!-- 左侧导航栏 -->
      <nav
        :class="['sidebar', 'bg-light', { 'd-none': !isSidebarOpen }]"
        style="min-width: 200px"
      >
        <ul class="nav flex-column p-3">
          <li class="nav-item">
            <router-link class="nav-link" to="/uploadImage">
              <i class="bi bi-house-door-fill me-2"></i> 首页
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/spider">
              <i class="bi bi-house-door-fill me-2"></i> 题
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/markdown">
              <i class="bi bi-house-door-fill me-2"></i> 编辑
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/chat">
              <i class="bi bi-house-door-fill me-2"></i> 聊天
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/uploadVideo">
              <i class="bi bi-house-door-fill me-2"></i> 上传视频
            </router-link>
          </li>
        </ul>
        <ul>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <i class="bi bi-clock-history me-2"></i> 历史记录
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <i class="bi bi-collection-play-fill me-2"></i> 播放列表
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <i class="bi bi-clock me-2"></i> 稍后观看
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <i class="bi bi-hand-thumbs-up-fill me-2"></i> 赞过的视频
            </a>
          </li>

          <!-- 左侧下拉菜单开始 -->
          <li class="nav-item">
            <a class="nav-link" href="#" @click="toggleLeftDropdown">
              <li class="bi bi-chevron-down me-2">订阅</li>
            </a>
            <ul v-if="isLeftDropdownOpen" class="nav flex-column ms-3">
              <li class="nav-item">
                <a class="nav-link" href="#"> 口语老炮儿马思瑞 </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#"> freeCodeCamp></a>
              </li>
              <!-- 更多的订阅项 -->
            </ul>
          </li>
          <!-- 左侧下拉菜单结束 -->
        </ul>
      </nav>

      <!-- 右侧内容区域 -->
      <main class="flex-grow-1 p-4">
        <div class="content">
          <router-view></router-view>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import $ from "jquery";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore(); // 获取 Vuex store

    // 响应式数据
    const isSidebarOpen = ref(true);
    const isLeftDropdownOpen = ref(false);
    const showModal = ref(false);
    const username = ref("");
    const password = ref("");

    // 使用 Vuex 状态
    const isLogin = computed(() => store.state.user.is_login);
    const avatar = computed(() => store.state.user.avatar);

    // 控制侧边栏
    const toggleSidebar = () => {
      isSidebarOpen.value = !isSidebarOpen.value;
    };

    // 控制左侧下拉菜单
    const toggleLeftDropdown = () => {
      isLeftDropdownOpen.value = !isLeftDropdownOpen.value;
    };

    // 显示登录框
    const showLoginModal = () => {
      showModal.value = true;
    };

    // 关闭登录框
    const closeLoginModal = () => {
      showModal.value = false;
    };

    // 登录操作
    const login = () => {
      console.log("登录用户名:", username.value);
      console.log("登录密码:", password.value);
      closeLoginModal(); // 关闭登录框
    };

    // 退出登录操作
    const logout = () => {
      store.commit("logout"); // 调用 Vuex 的 logout mutation
      console.log("已退出登录");
    };

    // QQ 登录
    const qqLogin = () => {
      $.ajax({
        url: "https://www.iweb3.top/qq/login",
        type: "POST",
        data: {},
        success: (res) => {
          console.log("登录成功:", res.data);
          // 将页面重定向到 res.data
          if (res && res.data) {
            window.location.href = res.data; // 重定向到返回的 URL
          } else {
            console.error("返回数据无效，无法重定向");
          }
        },
        error: (err) => {
          console.log("登录失败:", err);
        },
      });
    };

    // 返回响应式数据和方法
    return {
      isSidebarOpen,
      isLeftDropdownOpen,
      showModal,
      username,
      password,
      isLogin,
      avatar,
      toggleSidebar,
      toggleLeftDropdown,
      showLoginModal,
      closeLoginModal,
      login,
      qqLogin,
      logout,
    };
  },
};
</script>

<style>
.navbar {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  /* background-color: #f5f5f5; */
  color: #2c3e50;
  text-align: center;
  /* padding: 10px 0; */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* .sidebar {
    height: 100vh;
    transition: width 0.3s ease;
    background-color: #f8f9fa;
  } */
.sidebar {
  min-height: 100vh; /* 最小高度为视口高度 */
  height: auto; /* 高度根据内容自动调整 */
  background-color: #f8f9fa; /* 侧边栏背景色 */
}

.sidebar .nav-link {
  font-weight: 500;
  padding: 10px 15px;
  margin-bottom: 5px;
  color: #333;
  transition: background-color 0.3s, color 0.3s;
}

/* 左侧导航栏去掉圆点 */
.sidebar ul {
  list-style: none; /* 去掉列表圆点 */
  padding: 0; /* 去掉内边距 */
  margin: 0; /* 去掉外边距 */
}

.sidebar li {
  list-style: none; /* 去掉圆点 */
}

.sidebar .nav-link:hover,
.sidebar .nav-link.active {
  background-color: #e9ecef;
  color: #007bff;
  border-radius: 5px;
}

.d-flex {
  display: flex;
  flex-wrap: nowrap;
}

.flex-grow-1 {
  flex-grow: 1;
  overflow-x: hidden;
}

.list-group-item {
  cursor: pointer;
}

.list-group-item:hover {
  background-color: #e9ecef;
}

.modal-dialog {
  /* position: fixed; 固定定位 */
  top: 20%; /* 垂直居中 */
  /* left: 2%;              水平居中 */
  /* transform: translate(-50%, -50%);  通过平移调整模态框完全居中 */
  /* max-width: 100px; 设置最大宽度，可根据需求调整 */
  width: 0%; /* 设置宽度为100%，可以根据需要调整 */
}

.qqlogin {
  cursor: pointer;
}
</style>
