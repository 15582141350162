<template>
  <div>
    <div class="toolbar">
      <div class="left-toolbar">
        <label>编辑代码：</label>
      </div>
      <div class="right-toolbar">
        <label for="language">选择语言：</label>
        <select
          id="language"
          v-model="selectedLanguage"
          @change="changeLanguage"
        >
          <option value="cpp">C++</option>
          <option value="python">Python</option>
          <option value="java">Java</option>
          <option value="go">Go</option>
        </select>
        <label for="theme">选择主题：</label>
        <select id="theme" v-model="selectedTheme" @change="changeTheme">
          <option value="vs-dark">Dark</option>
          <option value="vs-light">Light</option>
        </select>
      </div>
    </div>
    <div id="code-editor" ref="codeEditorRef"></div>
    <button @click="submitCode" style="float: right">提交代码</button>
    <button @click="submitCode" style="float: right; margin-right: 10px">
      运行
    </button>
  </div>
</template>

<script>
import * as monaco from "monaco-editor";
import { onMounted, ref, toRaw } from "vue";
import $ from "jquery";

export default {
  setup() {
    const codeEditorRef = ref(null); // 绑定编辑器 DOM 节点
    const codeEditor = ref(null); // 编辑器实例
    const selectedLanguage = ref("cpp"); // 默认语言
    const selectedTheme = ref("vs-light"); // 默认主题
    const code = ref("// 在这里输入代码"); // 绑定编辑器的值

    // 切换语言函数
    const changeLanguage = () => {
      if (codeEditor.value) {
        monaco.editor.setModelLanguage(
          toRaw(codeEditor.value).getModel(),
          selectedLanguage.value
        );
      }
    };

    // 切换主题函数
    const changeTheme = () => {
      if (codeEditor.value) {
        monaco.editor.setTheme(selectedTheme.value);
      }
    };
    /*
// 在这里输入代码
#include <bits/stdc++.h>

using namespace std;

int main()
{
    int a, b;
    a = 10, b = 2;
    cout << a + b;

    return 0;
}

*/
    // 提交代码
    const submitCode = () => {
      //   alert(`提交的代码：\n${code.value}`);
      $.ajax({
        url: "http://127.0.0.1:2377/api/editor/submitCode",
        contentType: "application/json", // 指定数据类型为 JSON
        type: "post",
        data: JSON.stringify({
          code: code.value, // 代码内容
          // pid: 1,           // 示例的 pid 值
        }),
        success(resp) {
          //   alert(resp);
          console.log(resp);
          if (resp.code == 200) {
            alert(resp.data);
          }
        },
      });
    };

    onMounted(() => {
      if (!codeEditorRef.value) {
        return;
      }

      // 创建 Monaco Editor 实例
      codeEditor.value = monaco.editor.create(codeEditorRef.value, {
        value: "// 在这里输入代码", // 初始代码
        language: selectedLanguage.value, // 默认语言
        automaticLayout: true,
        readOnly: false,
        lineNumbers: "on",
        theme: selectedTheme.value, // 初始主题
        minimap: { enabled: false },
      });

      // 监听编辑器内容变化，将值实时绑定到 code
      codeEditor.value.onDidChangeModelContent(() => {
        code.value = toRaw(codeEditor.value).getValue(); // 更新绑定值
      });
    });

    return {
      codeEditorRef,
      codeEditor,
      selectedLanguage,
      selectedTheme,
      changeLanguage,
      changeTheme,
      submitCode,
    };
  },
};
</script>

<style scoped>
/* 工具栏样式 */
.toolbar {
  display: flex;
  justify-content: space-between; /* 左右布局 */
  margin-bottom: 10px;
}

.left-toolbar {
  display: flex;
  align-items: center;
}

.right-toolbar {
  display: flex;
  align-items: center;
  gap: 10px; /* 每个选项之间增加间距 */
}

#code-editor {
  width: 100%;
  height: 400px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: left;
}

button {
  margin-top: 10px;
  display: block;
  width: 100px;
  height: 40px;
  background-color: #ececec;
  color: #1f1f1f;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

button:hover {
  background-color: #ececec;
}
</style>
