<template>
    <div id="upload">
      <h1>图片上传示例</h1>
      <UploadForm />
    </div>
  </template>
  
  <script>
  import UploadForm from "@/components/UploadForm.vue";
  
  export default {
    components: {
      UploadForm,
    },
  };
  </script>
  