<template>
  <div class="container">
    <!-- 左侧内容 -->
    <div class="left">
      <div v-html="renderedContent"></div>
    </div>

    <!-- 右侧内容 -->
    <div class="right">
      <!-- 这里是右侧内容，可以添加其他内容 -->
      <button @click="clibtn">点击</button>
      <div class="markdown-editor">
        <!-- 用户输入的 Markdown 内容 -->
        <textarea
          v-model="markdownContent"
          placeholder="输入 Markdown 内容"
        ></textarea>

        <!-- 渲染实时效果 -->
        <div
          class="markdown-preview"
          v-html="renderedmarkdownEditorContent"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted, nextTick } from "vue"; // 导入 nextTick
import MarkdownIt from "markdown-it";
import katex from "markdown-it-katex";
import "katex/dist/katex.min.css"; // 引入 KaTeX 样式
import markdownit from "markdown-it";

export default {
  setup() {
    // 响应式数据
    const content = ref(
      "生成树计数\n\n### 题目描述\n\n给定一个 $n\\times m$ 的格点图，包含 $n$ 行 $m$ 列共 $n \\times m$\n个顶点，相邻的顶点之间有一条边。\n\n下图给出了一个 $3 \\times 4$ 的格点图的例子。\n\n![图片描述](\u002Fimage\u002Flq\u002Fuid1580206-20210202-1612249189269.png)\n\n如果在图中删除部分顶点和其相邻的边，如上图删除第 2 行第 3 列和第 3 行第 1 列的顶点后，如下图所示。\n\n![图片描述](\u002Fimage\u002Flq\u002Fuid1580206-20210202-1612249324700.png)\n\n图的生成树指包含图中的所有顶点和其中的一部分边，使得任意两个顶点之间都有由边构成的唯一路径。如果两个生成树包含有不同的边即被认为不同，则上图中共有 31 种不同的生成树，其中 $a$ 边不选有 10 种，$a$ 边选有 21 种。\n\n给出格点图中保留的顶点的信息，请计算该图一共有多少种不同的生成树。\n\n### 输入描述\n\n输入的第一行包含两个整数 $n, m$，用空格分隔，表示格点图的行数和列数。\n\n接下来 $n$ 行，每行 $m$ 个字母（中间没有分隔字符），每个字母必然是大写 $E$ 或大写 $N$，$E$ 表示对应的顶点存在，$N$ 表示对应的顶点不存在。保证存在至少一个顶点。\n\n其中，$1  \\leq  n  \\leq  6$，$1  \\leq  m  \\leq  10^5$。\n\n例如：\n\n样例输入：\n\n3 4\n\nEEEE\n\nEENE\n\nNEEE\n\n### 输出描述\n\n输出一行，包含一个整数，表示生成树的个数。答案可能很大，你只需要计算答案除以 $10^9+7$ 的余数即可。\n\n### 输入输出样例\n\n#### 示例\n\n\u003E 输入\n\n```txt\n3 4\nEEEE\nEENE\nNEEE\n```\n\n\u003E 输出\n\n```txt\n31\n```"
    );

    const markdownContent = ref(
      `### 欢迎使用 Markdown 编辑器\n\n您可以在这里输入 Markdown 格式的文本，看看实时效果！\n\n例如：数学公式 $E = mc^2$`
    );

    // 计算属性：渲染 Markdown 内容
    const renderedContent = computed(() => {
      const md = new MarkdownIt({ html: true }).use(katex); // 启用 KaTeX 支持
      console.log("content.val: ", content.value);
      return md.render(content.value);
    });

    const renderedmarkdownEditorContent = computed(() => {
      const md = new MarkdownIt({ html: true }).use(katex); // 启用 KaTeX 支持
      return md.render(markdownContent.value); // 渲染并返回 HTML
    });

    // 处理点击按钮的事件
    const clibtn = () => {
      const md = markdownit();
      const result = md.render(content.value); // 渲染 Markdown 内容
      console.log("result: ", result);
    };

    // 生命周期钩子：组件挂载完成后处理样式
    onMounted(() => {
      // 等待 DOM 加载完成后修改样式
      nextTick(() => {
        const targetElements = document.querySelectorAll(".vlist span[style]");
        targetElements.forEach((span) => {
          // 修改字体和样式
          span.style.fontSize = "0.7em";
          span.style.top = "-1.363em"; // 修改 top 样式
          span.style.marginRight = "0.05em"; // 修改 margin-right 样式
        });
      });
    });

    // 返回所有需要在模板中访问的变量和方法
    return {
      content,
      markdownContent,
      renderedContent,
      renderedmarkdownEditorContent,
      clibtn,
    };
  },
};
</script>

<style scoped>
/* 使用 Flexbox 创建左右布局 */
.container {
  display: flex;
  margin-top: 20px;
}

/* 左侧内容占 70% 宽度 */
.left {
  width: 70%;
  padding-right: 20px; /* 控制与右边内容的间距 */
  text-align: left; /* 右对齐 */
  background-color: white;
}

/* 右侧内容占 30% 宽度 */
.right {
  width: 30%;
  padding-left: 20px; /* 控制与左边内容的间距 */
  background-color: white;
}

/* 可选的样式调整 */
h3 {
  font-size: 1.5em;
  margin-top: 20px;
}

p {
  line-height: 1.6;
  font-size: 1em;
}

/* 样式设置 */
.markdown-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

textarea {
  width: 100%;
  height: 200px;
  font-size: 16px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.markdown-preview {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  white-space: pre-wrap;
}
</style>
