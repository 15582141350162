<template>
    <div class="hello">
        <p>mirey</p>
    </div>
</template>
  
<script>
export default {
    name: 'AboutVue',
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
  