<template>
    <div class="markdown-container">
      <!-- Markdown 输入框 -->
      <textarea
        v-model="content"
        @input="updateMarkdown"
        placeholder="请输入 Markdown 内容"
      ></textarea>
  
      <!-- 渲染后的 Markdown -->
      <div class="rendered-markdown" v-html="renderedContent"></div>
  
      <!-- 保存按钮 -->
      <button @click="saveMarkdown">保存内容</button>
    </div>
  </template>
  
  <script>
  import MarkdownIt from "markdown-it";
  import $ from "jquery"; // 引入 jQuery
  
  export default {
    name: "MarkdownEditor",
    data() {
      return {
        content: "", // Markdown 输入内容
        renderedContent: "", // 渲染后的 HTML
        md: new MarkdownIt(), // 初始化 markdown-it 实例
      };
    },
    methods: {
      // 实时更新 Markdown 渲染
      updateMarkdown() {
        console.log("Markdown 更新：", this.content);
        this.renderedContent = this.md.render(this.content);
        console.log("Markdown 渲染：", this.renderedContent);
      },
      // 保存 Markdown 数据到后端
      saveMarkdown() {
        // 使用 jQuery 发送 AJAX 请求
        $.ajax({
          url: "http://localhost:8080/api/markdown", // 后端接口地址
          type: "POST", // 请求方法
          contentType: "application/json", // 指定请求体内容类型
          data: JSON.stringify({ content: this.content }), // 发送的数据，需序列化为 JSON
          success: (response) => {
            alert("保存成功！响应：" + response.message);
          },
          error: (xhr, status, error) => {
            console.error("保存失败", error);
            alert("保存失败，请检查后端服务！");
          },
        });
      },
    },
  };
  </script>
  
  
  <style scoped>
  .markdown-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  textarea {
    width: 100%;
    height: 200px;
    font-size: 16px;
    padding: 10px;
  }
  
  .rendered-markdown {
    border: 1px solid #ccc;
    padding: 10px;
    background: #f9f9f9;
  }
  
  button {
    width: 100px;
    padding: 5px;
    font-size: 16px;
  }
  </style>
  