<template>
  <div class="container">
    <CodeEditor></CodeEditor>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">题目</th>
          <th scope="col">题解</th>
          <th scope="col">尝试次数/通过率</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in problems" :key="index">
          <th scope="row">{{ (currentPage - 1) * pageSize + index + 1 }}</th>
          <td @click="detailContent(item)">{{ item.title }}</td>
          <td>{{ item.id }}</td>
          <td>{{ item.try_counts }}/{{ item.pass_rate }}%</td>
        </tr>
      </tbody>
    </table>

    <!-- 分页控件 -->
    <div class="pagination">
      <button :disabled="currentPage === 1" @click="clickPage(currentPage - 1)">
        上一页
      </button>
      <span>第 {{ currentPage }} 页 / 共 {{ totalPages }} 页</span>
      <!-- 页码按钮 -->
      <span v-for="page in pages" :key="page">
        <button
          :class="{ active: page === currentPage }"
          @click="clickPage(page)"
        >
          {{ page }}
        </button>
      </span>
      <button
        :disabled="currentPage === totalPages"
        @click="clickPage(currentPage + 1)"
      >
        下一页
      </button>
    </div>

    <div class="markdown-preview" v-html="renderedmarkdownEditorContent"></div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from "vue";
import $ from "jquery";
import { useRouter } from "vue-router";
import MarkdownIt from "markdown-it";
import katex from "markdown-it-katex";
import "katex/dist/katex.min.css"; // 引入 KaTeX 样式
// import markdownit from "markdown-it";
import CodeEditor from "@/components/CodeEditor.vue";

export default {
  components: {
    CodeEditor,
  },
  setup() {
    // 响应式数据
    const problems = ref([]); // 问题列表
    const offset = ref(0); // 偏移量
    const currentPage = ref(1); // 当前页
    const pageSize = ref(10); // 每页显示的条数
    const totalPages = ref(10); // 总页数
    const pages = ref([]); // 显示的页码
    const router = useRouter();

    // 计算当前页的显示页码
    const updateVisiblePages = (curPage) => {
      console.log("curPage: ", curPage);
      console.log("totalPages: ", totalPages.value);

      let maxPage = Math.min(curPage + 2, totalPages.value);
      let minPage = Math.max(1, curPage - 2);
      pages.value = [];

      for (let i = minPage; i <= maxPage; i++) {
        pages.value.push(i);
      }
      console.log("pages: ", pages.value);
    };

    // const markdownContent = ref(
    // `### 欢迎使用 Markdown 编辑器\n\n您可以在这里输入 Markdown 格式的文本，看看实时效果！\n\n例如：数学公式 $E = mc^2$`
    // );
    const markdownContent = ref("");
    // 切换页码
    const clickPage = (page) => {
      offset.value = (page - 1) * pageSize.value;
      console.log("clickPage: ", page);
      console.log("offset: ", offset.value);

      if (page >= 0 && page <= totalPages.value) {
        currentPage.value = page;
        $.ajax({
          url: "http://127.0.0.1:2377/api/problems/",
          type: "POST",
          contentType: "application/json",
          data: JSON.stringify({
            current_page: offset.value,
            page_size: pageSize.value,
            tags: [],
          }),
          success: (resp) => {
            console.log(resp);
            problems.value = resp.data.problems;
            totalPages.value = resp.data.total_count;
            console.log(problems.value);
            updateVisiblePages(page);
            markdownContent.value = resp.data.problems[0].content
              .replace(/\\n/g, "\n") // 处理换行符
              .replace(/\\u003E/g, ">") // 转义 \u003E 为 ">"
              .replace(/\\\\/g, "\\")
              .replace(/\\u002F/g, "/")
              .replace(
                /https:\/\/doc\.shiyanlou\.com\/courses\/([^)\s]+)/g, // 匹配完整的路径
                "/image/lq/$1.png" // 替换为目标路径并添加 .png 后缀
              );
            // .replace(/\\leq/g, "\\\\leq");

            // .replace(/\\u002F/g, "/"); // 替换 Unicode 的斜杠为普通斜杠
            // .replace(
            //   /\(https?:\/\/[^/]+\/courses\/([^/)]+)\)/g, // 去掉多余的转义
            //   "(/image/lq/$1)"
            // );

            console.log("markdownContent.value: ", markdownContent.value);

            const true_val = ref(
              "倍数问题\n\n### 题目描述\n\n众所周知，小葱同学擅长计算，尤其擅长计算一个数是否是另外一个数的倍数。但小葱只擅长两个数的情况，当有很多个数之后就会比较苦恼。现在小葱给了你\n$n$ 个数，希望你从这 $n$ 个数中找到三个数，使得这三个数的和是 $K$ 的倍数，且这个和最大。数据保证一定有解。\n\n### 输入描述\n\n第一行包括 2 个正整数 $n, K$。\n\n第二行 $n$ 个正整数，代表给定的 $n$ 个数。\n\n其中，$1   \\leq   n   \\leq   10^5, 1   \\leq   K   \\leq   10^3$，给定的 $n$ 个数均不超过 $10^8$。\n\n### 输出描述\n\n输出一行一个整数代表所求的和。\n\n### 输入输出样例\n\n#### 示例\n\n\u003E 输入\n\n```txt\n4 3\n1 2 3 4\n```\n\n\u003E 输出\n\n```txt\n9\n```"
            );
            console.log("true_val: ", true_val.value);
          },
          error: (xhr) => {
            console.error(xhr);
          },
        });
      }
    };

    // 题目详情
    const detailContent = (item) => {
      console.log("detailContent: ", item);
      console.log("content: ", item.content);
      router.push({
        name: "problemsContent",
        query: {
          id: item.id,
          content: item.content,
        },
      });
    };

    const renderedmarkdownEditorContent = computed(() => {
      const md = new MarkdownIt({ html: true }).use(katex); // 启用 KaTeX 支持
      return md.render(markdownContent.value); // 渲染并返回 HTML
    });

    // 组件挂载时调用
    onMounted(() => {
      console.log(offset.value);
      clickPage(1);
    });

    // 返回暴露给模板的响应式变量和方法
    return {
      problems,
      offset,
      currentPage,
      pageSize,
      totalPages,
      pages,
      updateVisiblePages,
      clickPage,
      detailContent,
      renderedmarkdownEditorContent,
    };
  },
};
</script>

<style>
.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pagination button {
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #ddd;
  background-color: #f8f9fa;
  color: #333;
  border-radius: 3px;
}

.pagination button:disabled {
  background-color: #e9ecef;
  color: #999;
  cursor: not-allowed;
}
</style>
