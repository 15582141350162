<template>
  <p>这是右侧的主要内容区域。</p>
</template>
  
<script>
import { onMounted } from 'vue';
import $ from 'jquery';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore(); // 获取 Vuex store

    // 定义获取 token 和用户信息的逻辑
    const fetchUserInfo = () => {
      // 解析查询参数
      const params = new URLSearchParams(window.location.search);
      const accessToken = params.get("access_token");
      const refreshToken = params.get("refresh_token");

      if (accessToken && refreshToken) {
        console.log("access_token: " + accessToken);
        console.log("refresh_token: " + refreshToken);

        // 清除 URL 中的 access_token 和 refresh_token
        const newUrl = window.location.origin + window.location.pathname;
        window.history.replaceState({}, document.title, newUrl);

        // 发送请求获取用户信息
        $.ajax({
          url: "https://www.iweb3.top/api/user/getInfo",
          type: "post",
          headers: { 
            Authorization: "bearer " + accessToken,
          },
          data: {},
          success(resp) {
            if (resp.msg === "success") {
              console.log("data: ", resp.data);
              localStorage.setItem("access_token", accessToken);
              localStorage.setItem("refresh_token", refreshToken);

              // 使用 Vuex 更新用户信息和 token
              store.commit("updateLoginUser", resp.data);
              const tokenList = { access_token: accessToken, refresh_token: refreshToken };
              store.commit("updateToken", tokenList);
            }
          },
          error() {
            // 错误处理
            console.log("请求失败");
          }
        });
      }
    };

    // 在 onMounted 中立即执行 fetchUserInfo 函数
    onMounted(() => {
      fetchUserInfo();
    });
 
    return {};
  }
};
</script>

  
<style scoped></style>
  