<template>
  <div>
    <!-- 表单 -->
    <form @submit.prevent="submitForm" enctype="multipart/form-data">
      <!-- 创建人输入框 -->
      <label for="creator">创建人：</label>
      <input type="text" id="creator" v-model="creator" required />

      <!-- 图片上传控件 -->
      <label for="addr_photos">选择场地图片：</label>
      <input type="file" id="addr_photos" multiple @change="handleAddrFiles" />

      <!-- 图片上传控件 -->
      <label for="business_license">选择营业执照：</label>
      <input type="file" id="business_license" @change="handleBusinessFiles" />

      <!-- 提交按钮 -->
      <button type="submit">提交</button>
    </form>

    <!-- 显示服务器响应 -->
    <div v-if="response">
      <h3>服务器响应：</h3>
      <pre>{{ response }}</pre>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  data() {
    return {
      creator: "", // 创建人信息
      phone: "18859568635", // 手机号
      addr: "北京111", // 地址
      addr_photos: [], // 场地图片文件
      business_license: null, // 营业执照文件
      addr_photos_urls: [], // 场地图片的 URL
      business_license_url: "", // 营业执照的 URL
      response: null, // 服务器响应数据
    };
  },
  methods: {
    // 处理场地图片文件选择事件
    handleAddrFiles(event) {
      this.addr_photos = event.target.files; // 存储场地图片文件
    },

    // 处理营业执照文件选择事件
    handleBusinessFiles(event) {
      this.business_license = event.target.files[0]; // 存储营业执照文件
    },

    // 上传单张图片并获取 URL
    uploadSingleImage(file, type) {
  return new Promise((resolve, reject) => {
    // 构造 FormData
    const formData = new FormData();
    formData.append("type", type); // 对应 Apifox 的 "type" 参数
    formData.append("image", file); // 对应 Apifox 的 "image" 参数

    // 打印调试信息，确保参数和文件被正确添加
    console.log("Uploading image with type:", type);
    formData.forEach((value, key) => {
      if (key === "image") {
        console.log(`${key}: [File Object: ${value.name}]`);
      } else {
        console.log(`${key}: ${value}`);
      }
    });

    // 发送请求
    $.ajax({
      url: "https://47.96.75.98:8080/user/UploadImage", // Apifox 使用的 URL
      type: "POST", // 请求方法
      data: formData, // 表单数据
      processData: false, // 不让 jQuery 自动处理数据
      contentType: false, // 让 jQuery 自动设置 Content-Type
      success: (res) => {
        console.log("上传成功:", res);
        resolve(res.data); // 返回成功的响应数据
      },
      error: (xhr, status, error) => {
        console.log(error);
        reject(error); // 返回错误信息
      },
    });
  });
},

    // 上传所有图片并获取 URLs
    async uploadImages() {
      try {
        // 上传场地图片
        const addrPhotosPromises = Array.from(this.addr_photos).map((file) =>
          this.uploadSingleImage(file, "addr_photo")
        );
        console.log("addrPhotosPromises：", addrPhotosPromises);
        this.addr_photos_urls = await Promise.all(addrPhotosPromises);

        // 上传营业执照
        if (this.business_license) {
          this.business_license_url = await this.uploadSingleImage(
            this.business_license,
            "business_license"
          );
          console.log("-----)");
          console.log(await this.uploadSingleImage(
            this.business_license,
            "business_license"
          ));
        }
      } catch (error) {
        throw new Error("图片上传失败，请重试");
      }
    },

    // 提交表单
    async submitForm() {
      try {
        // 先上传图片
        await this.uploadImages();

        // 创建 FormData 对象
        const formData = new FormData();
        formData.append("phone", this.phone); // 添加手机号
        formData.append("addr", this.addr); // 添加地址
        formData.append("business_license", this.business_license_url); // 添加营业执照 URL

        // 添加场地图片 URL
        this.addr_photos_urls.forEach((url) => {
          formData.append("addr_photos", url); // 每个图片 URL 都是一个表单项
        });

        // 调用 applyMerchant 接口
        $.ajax({
          url: "https://47.96.75.98:8080/user/applyKTV",
          type: "POST",
          data: formData,
          processData: false, // 不转换 FormData 为字符串
          contentType: false, // 让浏览器自动设置 multipart/form-data 类型
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxIiwiaWF0IjoxNzMzMTY4MjExLCJleHAiOjE3MzM3NzMwMTF9.UX3h_KhLYJq-EIWyEy7ZMdlLM1vR3yxuKl-CDNWl5o0",
          },
          success: (res) => {
            console.log("提交成功:", res);
            this.response = res; // 显示服务器返回的数据
          },
          error: (xhr, status, error) => {
            console.error("提交失败:", error);
            this.response = { error: "提交失败，请检查服务器连接。" };
          },
        });
      } catch (error) {
        console.error("操作失败:", error);
        this.response = { error: error.message };
      }
    },
  },
};
</script>

<style>
form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;
  margin: auto;
}

button {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
