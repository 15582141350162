<template>
  <div>
    <h3>上传视频</h3>
    <!-- 视频上传表单 -->
    <input type="file" id="videoFile" accept="video/*" />
    <button id="uploadBtn">上传</button>

    <!-- 视频预览 -->
    <div id="videoPreview" style="margin-top: 20px; display: none">
      <h4>视频预览：</h4>
      <video id="uploadedVideo" controls style="width: 100%"></video>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  mounted() {
    // 注册事件，当组件挂载时绑定按钮的点击事件
    $(document).ready(() => {
      // 上传按钮点击事件
      $("#uploadBtn").click(() => {
        const fileInput = $("#videoFile")[0];
        if (fileInput.files.length === 0) {
          alert("请选择一个视频文件！");
          return;
        }

        // 构建 FormData 对象，用于传输文件
        const formData = new FormData();
        formData.append("file", fileInput.files[0]);

        // AJAX 请求上传视频
        $.ajax({
          url: "http://localhost:8080/upload", // 后端上传接口地址
          type: "POST",
          data: formData,
          processData: false, // 不对 FormData 数据进行处理
          contentType: false, // 不设置默认的 Content-Type
          success: function (response) {
            console.log("上传成功:", response);
            // 显示视频预览
            $("#videoPreview").show();
            $("#uploadedVideo").attr("src", response.videoURL);
          },
          error: function (err) {
            console.error("上传失败:", err);
            alert("视频上传失败！");
          },
        });
      });
    });
  },
};
</script>
